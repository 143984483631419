var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{ref:"delivery-notes-table",attrs:{"resource":"delivery-notes","resourceStore":'deliveryNotes',"columns":_vm.columns,"filters":_vm.baseFilters,"hide-toolbar":_vm.hideToolbar,"loading":_vm.localLoading || _vm.loading,"totals-configuration":_vm.totalsConfiguration,"excluded-filters":_vm.baseExcludeFilters,"disable-pagination":_vm.disablePagination},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearListFilters,"row-clicked":function($event){return _vm.$router.push({ name: 'viewDeliveryNotes', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"cell(provider)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.provider? data.item.provider.name : ''))])]}},{key:"cell(order)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.order? data.item.order.number : ''))])]}},{key:"cell(job)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.job? data.item.job.code : ''))])]}},{key:"cell(purchase_invoice)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.purchase_invoice.number))])]}},{key:"cell(total_gross)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_gross))+" €")])]}},{key:"cell(total_delivery_note)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("numberToLocalString")(data.item.total_delivery_note))+" €")])]}},{key:"cell(status)",fn:function(data){return [(data.item.status.name)?_c('StatusBadge',{attrs:{"status":data.item.status.name}}):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.documentation && item.documentation.length > 0)?_c('b-link',{staticClass:"d-inline-block text-indigo pr-1"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Descargar albaran","icon":"DownloadIcon","size":"18"},on:{"click":function($event){$event.stopPropagation();return _vm.handlePrintIconClick(item)}}})],1):_vm._e(),_c('b-link',{staticClass:"pr-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewDeliveryNotes', params: { id: item.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
          resource: _vm.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,
          resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }),expression:"{\n          resource: $data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,\n          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n        }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.handleDeleteIconClick(item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)]}},{key:"footer",fn:function(ref){
        var data = ref.data;
return [(_vm.footerTotalsVisible)?_c('b-tr',{staticClass:"bg-action-blue"},[_c('b-td',{attrs:{"colspan":"4"}}),_c('b-td',{staticClass:"text-right text-uppercase font-weight-bold"},[_vm._v(" Totales: ")]),_c('b-td',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(data.totals.total_delivery_notes || 0)+" ")]),_c('b-td',{attrs:{"colspan":"1"}})],1):_vm._e()]}}])}),(!_vm.hideToolbar)?_c('DeliveryNotesListFilters',{ref:"delivery-notes-list-filters",on:{"filters-submit":function($event){return _vm.$store.commit('deliveryNotes/setFilters', $event)}},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }