import { BaseApi } from '@/api/base-api'
import { RESOURCE_DELIVERY_NOTES } from '@/shared/constants/resources'
import Vue from 'vue'

class DeliveryNotesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  createFormPurchaseInvoice(purchaseInvoiceId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/create-from-purchase-invoice/${purchaseInvoiceId}`)
        resolve(response.data)
        Vue.$toast('Recurso creado con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al crear el recurso. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }

  createFormOrder(orderId) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/create-from-order/${orderId}`)
        resolve(response.data)
        Vue.$toast('Recurso creado con éxito.')
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al crear el recurso. Por favor inténtelo de nuevo más tarde.')
        reject(error)
      }
    })
  }
}

export default new DeliveryNotesApi(RESOURCE_DELIVERY_NOTES)
